var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "nav-row d-flex align-center justify-space-between",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-card', {
    staticClass: "pa-1 rounded",
    attrs: {
      "flat": ""
    }
  }, [_c('v-menu', {
    attrs: {
      "open-on-click": "",
      "close-on-click": "",
      "close-on-content-click": true,
      "elevation": "0",
      "min-width": "100%",
      "bottom": "",
      "nudge-bottom": "",
      "offset-y": "",
      "transition": "scroll-y-transition"
    },
    on: {
      "input": function ($event) {
        return _vm.onMenuInput($event, 'library');
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-sheet', _vm._g({
          staticClass: "menu-btn primary--text d-flex align-center justify-center text-truncate cursor-pointer",
          class: ("flex-" + (_vm.menuLabelDirection) + " " + (_vm.navItemSelected === 'library' ? 'primary secondary--text' : 'secondary primary--text') + " "),
          attrs: {
            "height": "45px",
            "rounded": "",
            "width": "100%"
          }
        }, on), [_c('v-icon', {
          attrs: {
            "left": _vm.menuLabelDirection === 'row',
            "color": _vm.navItemSelected === 'library' ? 'secondary' : 'primary'
          }
        }, [_vm._v("mdi mdi-bank")]), _c('font', {
          staticClass: "font-weight-bold",
          class: _vm.menuLabelDirection === 'column' ? 'caption' : 'ml-1 '
        }, [_vm._v(_vm._s(_vm.menuLabelText('Your Library ')))])], 1)];
      }
    }])
  }, [_c('v-card', {
    staticClass: "subnav-menu-card mx-auto",
    class: _vm.breakpoint.smAndDown ? 'mt-4' : 'mt-4',
    attrs: {
      "rounded": "",
      "width": "98%"
    }
  }, [_c('v-card-text', {
    staticClass: "primary--text subNavPanelCardText"
  }, [_c('v-scale-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "space-around",
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-column justify-space-between pa-0",
    attrs: {
      "align-self": "stretch",
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "large": ""
    }
  }, [_vm._v("mdi mdi-bank")]), _c('span', [_vm._v("DEPARTMENTS")])], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('TileDepartmentNav')], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "mt-12 mt-md-0",
    attrs: {
      "color": "transparent",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "large": ""
    }
  }, [_vm._v("mdi mdi-room-service")]), _c('span', [_vm._v("SERVICES")])], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('ul', {
    staticClass: "font-weight-bold text-h6 primary--text text-decoration-none"
  }, [_c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/blood-pressure-kit"
    }
  }, [_vm._v("Blood Pressure Kit")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/career-counselor"
    }
  }, [_vm._v("Career Counseling")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/discount-tickets"
    }
  }, [_vm._v("Discount Tickets")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/homebound-service"
    }
  }, [_vm._v("Homebound Program")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/library-card"
    }
  }, [_vm._v("Library Card")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/library-of-things"
    }
  }, [_vm._v("Library of Things")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/little-free-library"
    }
  }, [_vm._v("Little Free Libraries")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/maker-lab"
    }
  }, [_vm._v("Maker Lab")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/museum-passes"
    }
  }, [_vm._v("Museum Passes")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/remote-printing"
    }
  }, [_vm._v("Remote Printing")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/services/select-books"
    }
  }, [_vm._v("Select Books")])], 1)])])], 1)], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "mt-12 mt-md-0",
    attrs: {
      "color": "transparent",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-6"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "large": ""
    }
  }, [_vm._v("mdi mdi-information")]), _c('span', [_vm._v("INFORMATION")])], 1), _c('v-card-text', {
    staticClass: "d-flex flex-column font-weight-bold text-body-1 primary--text text-decoration-none pa-0 pl-2"
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center mb-4",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center primary--text text-no-wrap mr-4",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": ""
    }
  }, [_vm._v("mdi-phone-classic")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("(631) 581-9200")])], 1), _c('v-sheet', {
    staticClass: "d-flex align-center primary--text text-no-wrap",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": ""
    }
  }, [_vm._v("mdi-email")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("help@eipl.org")])], 1)], 1), _c('v-sheet', {
    staticClass: "d-flex align-start primary--text text-no-wrap mb-4",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-icon', {
    staticClass: "mt-2",
    attrs: {
      "color": "primary",
      "left": ""
    }
  }, [_vm._v("mdi-map-marker")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v(" 381 East Main Street "), _c('br'), _vm._v(" East Islip, NY 11730 ")])], 1), _c('v-sheet', {
    staticClass: "d-flex align-start primary--text text-no-wrap mb-4",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-icon', {
    staticClass: "mt-2",
    attrs: {
      "color": "primary",
      "left": ""
    }
  }, [_vm._v("mdi-clock")]), _c('v-sheet', {
    staticClass: "d-flex align-center justify-start primary--text text-left",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex flex-shrink-1 flex-column text-subtitle-2 primary--text",
    attrs: {
      "color": "transparent",
      "width": "150px"
    }
  }, [_c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("MON - THUR:")]), _c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("FRIDAY:")]), _c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("SATURDAY:")]), _c('p', {
    staticClass: "font-weight-bold ma-0"
  }, [_vm._v("SUNDAY* :")]), _c('p', {
    staticClass: "font-weight-bold text-caption ma-0"
  }, [_vm._v("*(Oct. - Apr.)")])]), _c('v-sheet', {
    staticClass: "d-flex flex-column align-start primary--text text-center text-subtitle-2 text-no-wrap",
    attrs: {
      "color": "transparent"
    }
  }, [_c('p', {
    staticClass: "ma-0"
  }, [_vm._v("9:00 AM - 9:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("9:00 AM - 6:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("9:00 AM - 5:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v("1:00 PM - 5:00 PM")]), _c('p', {
    staticClass: "ma-0"
  }, [_vm._v(" ")])])], 1)], 1), _c('v-sheet', {
    staticClass: "sheet-anchor d-flex flex-column align-start justify-start text-decoration-none mt-4",
    attrs: {
      "tag": "a",
      "href": "https://secure.qgiv.com/for/eipl/",
      "target": "_blank"
    }
  }, [_c('p', {
    staticClass: "font-weight-bold text-h6 primary--text text-decoration-none mb-1"
  }, [_vm._v("Support your library!")]), _c('img', {
    staticClass: "rounded",
    attrs: {
      "alt": "Hands holding up letter balloons spelling the word donate.",
      "height": "80",
      "width": "280",
      "src": "https://media.eipl.org/site/images/site/donate.jpg"
    }
  })])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "pa-1 rounded",
    attrs: {
      "flat": ""
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-click": "",
      "close-on-content-click": true,
      "open-on-click": "",
      "elevation": "0",
      "min-width": "100%",
      "bottom": "",
      "nudge-bottom": "",
      "offset-y": "",
      "transition": "scroll-y-transition"
    },
    on: {
      "input": function ($event) {
        return _vm.onMenuInput($event, 'browse');
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-sheet', _vm._g({
          staticClass: "menu-btn d-flex align-center justify-center primary--text text-truncate cursor-pointer",
          class: ("flex-" + (_vm.menuLabelDirection) + " " + (_vm.navItemSelected === 'browse' ? 'primary secondary--text' : 'secondary primary--text') + " "),
          attrs: {
            "height": "45px",
            "rounded": "",
            "width": "100%"
          }
        }, on), [_c('v-icon', {
          attrs: {
            "left": _vm.menuLabelDirection === 'row',
            "color": _vm.navItemSelected === 'browse' ? 'secondary' : 'primary'
          }
        }, [_vm._v("mdi mdi-book-open-page-variant-outline")]), _c('font', {
          staticClass: "font-weight-bold",
          class: _vm.menuLabelDirection === 'column' ? 'caption' : 'ml-1 '
        }, [_vm._v(_vm._s(_vm.menuLabelText('Browse & Borrow')))])], 1)];
      }
    }])
  }, [_c('v-card', {
    staticClass: "subnav-menu-card mx-auto",
    class: _vm.breakpoint.smAndDown ? 'mt-4' : 'mt-4',
    attrs: {
      "rounded": "",
      "width": "98%"
    }
  }, [_c('v-card-text', {
    staticClass: "primary--text subNavPanelCardText"
  }, [_c('v-scale-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "space-around",
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "large": ""
    }
  }, [_vm._v("mdi mdi-book-open-page-variant-outline")]), _c('span', [_vm._v("BROWSE")])], 1), _c('v-card-text', {
    staticClass: "d-flex flex-sm-column align-start justify-space-between pa-0"
  }, [_c('v-sheet', {
    staticClass: "d-block d-md-block",
    attrs: {
      "color": "transparent",
      "width": "100%"
    }
  }, [_c('p', {
    staticClass: "text-h6 primary--text font-weight-bold mb-2"
  }, [_vm._v("CATALOG")]), _c('ul', {
    staticClass: "font-weight-bold text-h6 primary--text text-decoration-none text-no-wrap"
  }, [_c('li', [_c('a', {
    staticClass: "nav-link",
    attrs: {
      "href": "https://search.livebrary.com/search~S20/a",
      "target": "_blank"
    }
  }, [_vm._v("Author")])]), _c('li', [_c('a', {
    staticClass: "nav-link",
    attrs: {
      "href": "https://search.livebrary.com/search~S20/X",
      "target": "_blank"
    }
  }, [_vm._v("Keyword")])]), _c('li', [_c('a', {
    staticClass: "nav-link",
    attrs: {
      "href": "https://search.livebrary.com/search~S20/d",
      "target": "_blank"
    }
  }, [_vm._v("Subject")])]), _c('li', [_c('a', {
    staticClass: "nav-link",
    attrs: {
      "href": "https://search.livebrary.com/search~S20/t",
      "target": "_blank"
    }
  }, [_vm._v("Title")])])])]), _c('v-sheet', {
    staticClass: "d-none d-md-block",
    attrs: {
      "color": "transparent"
    }
  }, [_c('p', {
    staticClass: "text-h6 primary--text font-weight-bold mt-0 mt-sm-6 mb-2"
  }, [_vm._v("NEWSLETTER")]), _c('a', {
    attrs: {
      "href": "https://media.eipl.org/documents/newsletter/JAN-FEB_2025.pdf",
      "target": "_blank"
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var hover = _ref3.hover;
        return [_c('img', {
          staticClass: "rounded",
          class: ("elevation-" + (hover ? '10' : '5')),
          attrs: {
            "src": "https://media.eipl.org/site/images/pages/newsletter/2025/jan-feb.png",
            "alt": "",
            "height": "200px",
            "aspect-ration": "4/3"
          }
        })];
      }
    }])
  })], 1)])], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "mt-12 mt-sm-0 mt-md-0",
    attrs: {
      "color": "transparent",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "large": ""
    }
  }, [_vm._v("mdi mdi-package-variant")]), _c('span', [_vm._v("NEW ARRIVALS")])], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('ul', {
    staticClass: "font-weight-bold text-h6 primary--text text-decoration-none"
  }, [_c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/new-material/audio-book"
    }
  }, [_vm._v("Audiobook")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/new-material/bluray"
    }
  }, [_vm._v("Bluray")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/new-material/dvd"
    }
  }, [_vm._v("DVD")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/new-material/fiction"
    }
  }, [_vm._v("Fiction")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/new-material/cd"
    }
  }, [_vm._v("Music CD")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/new-material/non-fiction"
    }
  }, [_vm._v("Non-Fiction")])], 1), _c('li', [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": "/new-material/ya"
    }
  }, [_vm._v("Young Adult")])], 1)])])], 1)], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "mt-12 mt-md-0",
    attrs: {
      "color": "transparent",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "large": ""
    }
  }, [_vm._v(" mdi-heart-search")]), _c('span', [_vm._v("DISCOVER")])], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "d-block d-md-none"
  }, [_c('v-sheet', {
    attrs: {
      "color": "transparent"
    }
  }, [_c('p', {
    staticClass: "text-h6 primary--text font-weight-bold mt-0 mt-md-4 mb-2"
  }, [_vm._v("NEWSLETTER")]), _c('a', {
    attrs: {
      "href": "https://media.eipl.org/documents/newsletter/JAN-FEB_2025.pdf",
      "target": "_blank"
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var hover = _ref4.hover;
        return [_c('img', {
          staticClass: "rounded",
          class: ("elevation-" + (hover ? '10' : '5')),
          attrs: {
            "src": "https://media.eipl.org/site/images/pages/newsletter/2025/jan-feb.png",
            "alt": "",
            "height": "200px",
            "aspect-ration": "4/3"
          }
        })];
      }
    }])
  })], 1)])], 1), _c('v-col', [_c('v-sheet', {
    staticClass: "sheet-anchor d-flex flex-column align-start justify-start text-decoration-none mt-4 mt-sm-0",
    attrs: {
      "tag": "a",
      "href": "https://eiplblog.wordpress.com/",
      "target": "_blank"
    }
  }, [_c('p', {
    staticClass: "font-weight-bold text-h6 primary--text text-decoration-none mb-0"
  }, [_vm._v("Between the Lines")]), _c('span', {
    staticClass: "text-caption primary--text text-decoration-none mt-n2 mb-1"
  }, [_vm._v("Reading Blog")]), _c('img', {
    staticClass: "rounded",
    attrs: {
      "alt": "front of public library at dusk.",
      "height": "80",
      "width": "280",
      "src": "https://eiplblog.wordpress.com/wp-content/uploads/2015/04/cropped-eipl22.jpg"
    }
  })]), _c('v-sheet', {
    staticClass: "sheet-anchor d-flex flex-column align-start justify-start text-decoration-none mt-4",
    attrs: {
      "tag": "a",
      "href": "https://libraryaware.com/273/Subscribers/Subscribe?optInPageId=f07f0112-afff-4306-99e6-7b2b7c85abc6",
      "target": "_blank"
    }
  }, [_c('p', {
    staticClass: "font-weight-bold text-h6 primary--text text-decoration-none mb-0"
  }, [_vm._v("NextReads")]), _c('span', {
    staticClass: "text-caption primary--text text-decoration-none mt-n2 mb-1"
  }, [_vm._v("Tailored Newsletters")]), _c('img', {
    staticClass: "rounded",
    attrs: {
      "alt": "front of public library at dusk.",
      "height": "80",
      "width": "280",
      "src": "https://media.eipl.org/site/images/site/librarian.jpg"
    }
  })]), _c('v-card', {
    staticClass: "sheet-anchor d-flex flex-column align-start justify-start text-decoration-none mt-4",
    attrs: {
      "flat": "",
      "to": "/services/select-books"
    }
  }, [_c('p', {
    staticClass: "font-weight-bold text-h6 primary--text text-decoration-none mb-0"
  }, [_vm._v("Select Books")]), _c('span', {
    staticClass: "text-caption primary--text text-decoration-none mt-n2 mb-1"
  }, [_vm._v("Personalized Recommendations")]), _c('img', {
    staticClass: "rounded",
    staticStyle: {
      "object-position": "top center",
      "object-fit": "cover"
    },
    attrs: {
      "alt": "front of public library at dusk.",
      "height": "80",
      "width": "280",
      "src": "https://media.eipl.org/site/images/services/select-books.jpg"
    }
  })])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "pa-1 rounded",
    attrs: {
      "flat": ""
    }
  }, [_c('v-menu', {
    attrs: {
      "open-on-click": "",
      "close-on-click": "",
      "close-on-content-click": true,
      "elevation": "0",
      "min-width": "100%",
      "bottom": "",
      "nudge-bottom": "",
      "offset-y": "",
      "transition": "scroll-y-transition"
    },
    on: {
      "input": function ($event) {
        return _vm.onMenuInput($event, 'digital');
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on;
        return [_c('v-sheet', _vm._g({
          staticClass: "menu-btn primary--text d-flex align-center justify-center text-truncate cursor-pointer",
          class: ("flex-" + (_vm.menuLabelDirection) + " " + (_vm.navItemSelected === 'digital' ? 'primary secondary--text' : 'secondary primary--text') + " "),
          attrs: {
            "height": "45px",
            "rounded": "",
            "width": "100%"
          }
        }, on), [_c('v-icon', {
          attrs: {
            "left": _vm.menuLabelDirection === 'row',
            "color": _vm.navItemSelected === 'digital' ? 'secondary' : 'primary'
          }
        }, [_vm._v("mdi mdi-cloud-download-outline")]), _c('font', {
          staticClass: "font-weight-bold",
          class: _vm.menuLabelDirection === 'column' ? 'caption' : 'ml-1 '
        }, [_vm._v(_vm._s(_vm.menuLabelText('Digital Resources')))])], 1)];
      }
    }])
  }, [_c('v-card', {
    staticClass: "subnav-menu-card mx-auto",
    class: _vm.breakpoint.smAndDown ? 'mt-4' : 'mt-4',
    attrs: {
      "rounded": "",
      "width": "98%"
    }
  }, [_c('v-card-text', {
    staticClass: "primary--text subNavPanelCardText"
  }, [_c('v-scale-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "space-around",
      "align": "start"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "flat": "",
      "width": "100%"
    }
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center justify-start flex-nowrap text-no-wrap text-h5 font-weight-bold primary--text pa-0 pb-4"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "large": ""
    }
  }, [_vm._v("mdi mdi-cloud-download-outline")]), _c('span', [_vm._v("DOWNLOAD & STREAM")])], 1), _c('v-card-text', {
    staticClass: "d-flex flex-column align-start justify-start text-decoration-none pa-0"
  }, _vm._l(_vm.digitalDownload, function (item) {
    return _c('v-card', {
      key: item.id,
      staticClass: "mt-2",
      attrs: {
        "hover": "",
        "href": item.href,
        "target": "_blank",
        "width": "90%"
      }
    }, [_c('v-card-text', {
      staticClass: "d-flex flex-row align-center justify-start text-decoration-none pa-2"
    }, [_c('v-avatar', {
      staticClass: "mr-4",
      attrs: {
        "size": "40",
        "color": "primary"
      }
    }, [_c('img', {
      attrs: {
        "src": item.logo,
        "alt": "alt"
      }
    })]), _c('p', {
      staticClass: "text-h6 primary--text font-weight-bold mb-0"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)], 1);
  }), 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    staticClass: "mt-12 mt-sm-0 mt-md-0",
    attrs: {
      "color": "transparent",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "d-flex align-center justify-start text-h5 font-weight-bold primary--text pa-0 pb-4"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "large": ""
    }
  }, [_vm._v("mdi mdi-school")]), _c('span', [_vm._v("RESEARCH")])], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, _vm._l(_vm.digitalResearch, function (item) {
    return _c('v-card', {
      key: item.id,
      staticClass: "mt-2",
      attrs: {
        "hover": "",
        "href": item.href,
        "target": "_blank",
        "width": "90%"
      }
    }, [_c('v-card-text', {
      staticClass: "d-flex flex-row align-center justify-start text-decoration-none pa-2"
    }, [_c('v-avatar', {
      staticClass: "mr-4",
      attrs: {
        "size": "40",
        "color": "primary"
      }
    }, [_c('img', {
      attrs: {
        "src": item.logo,
        "alt": "alt"
      }
    })]), _c('p', {
      staticClass: "text-h6 primary--text font-weight-bold mb-0"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }